/* Ensure the entire layout fills the viewport without excess height */
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Vertically centers the content */
    align-items: center;      /* Horizontally centers the content */
    background-color: #f4f4f4; /* Light background for the page */
}

.content {
    text-align: center;       /* Centers text inside the content div */
    background-color: #f8f8f8;
    padding: 40px;
    border-radius: 10px;
    max-width: 600px;         /* Limits the width of the content */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
    font-size: 1.2em;
    line-height: 1.6;
}

/* Button Styling */
.button {
    background-color: #000;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 1.1em;
    cursor: pointer;
    border-radius: 30px;
    margin-top: 20px;
    transition: background-color 0.3s ease; /* Adds smooth transition on hover */
}

.button:hover {
    background-color: #333;
}

/* Add responsive design */
@media (max-width: 768px) {
    .content {
        padding: 30px;
        font-size: 1em;
    }

    .button {
        padding: 12px 25px;
    }
}
.permissions-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .permissions-container h1 {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .permissions-container p {
    font-size: 1em;
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .permissions-list {
    list-style-type: none;
    padding: 0;
  }
  
  .permissions-list li {
    margin-bottom: 15px;
    font-size: 1.1em;
    color: #333;
  }
  
  .permissions-list li strong {
    color: #000;
  }
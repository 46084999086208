/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-logo {
  height: 50px; /* 设置 logo 高度 */
  width: auto;  /* 自动调整宽度以保持比例 */
  margin-left: 20px; /* 根据需要调整间距 */
}

body, html {
  height: 100%;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #f4f4f4;
}

/* Layout */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* min-height: 100vh; */
}

.header {
  background-color: #000;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;  /* 固定头部 */
  width: 100%;
  z-index: 1000;  /* 确保头部在最上层 */
}

.menu-button {
  background: none;
  border: none;
  color: white;
  font-size: 2em;
  position: absolute; /* 菜单按钮在左上角 */
  left: 20px;
  cursor: pointer;
}

.header h1 {
  font-size: 2em;
  text-align: center;
  color: white;
  margin: 0;
}

/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 60px;  /* 保留给固定头部的空间 */
  left: -250px;
  width: 250px;
  height: 100%;
  background-color: #333;
  padding-top: 20px;
  transition: left 0.3s ease;
}

.sidebar.open {
  left: 0;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  margin: 20px;
  text-align: center; /* 链接居中 */
}

.sidebar ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.2em;
  padding: 10px;
  display: block;
  background-color: #444;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.sidebar ul li a:hover {
  background-color: #555;
}

/* Main Content */
.main-content {
  flex-grow: 1;  /* 占据中间所有可用空间 */
  display: flex;
  justify-content: center;  /* 水平居中 */
  align-items: center;      /* 垂直居中 */
  /* padding: 100px 40px 40px 40px; */
  transition: margin-left 0.3s ease;
  margin-left: 0;  /* 重置 margin-left */
  overflow: auto;
}

.sidebar.open + .main-content {
  margin-left: 250px; /* 菜单打开时，主内容右移 */
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto; /* 使容器水平居中 */
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center; /* 进一步确保内容居中 */
  align-items: center;
}

.content {
  background-color: #f8f8f8;
  padding: 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 800px; /* 设置内容最大宽度 */
}

/* Button */
.button {
  background-color: #000;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.1em;
  cursor: pointer;
  border-radius: 30px;
  margin-top: 20px;
}

.button:hover {
  background-color: #333;
}

/* Footer */
.footer {
  background-color: #000;
  color: white;
  padding: 10px;
  text-align: center;
  margin-top: auto;
  flex-shrink: 0;
  z-index: 1000;
}
.sdk-list-container {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #fff;
    color: #333;
    max-width: 800px;   /* Set a maximum width for the container */
    margin: 20px auto;  /* Center the container horizontally */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    border-radius: 8px; /* Add rounded corners */
    display: block;     /* Ensure the container is a block-level element */
  }
  
  .sdk-item {
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center; /* Center the title */
  }
  
  h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 14px;
    line-height: 1.6;
  }
  
  a {
    color: #1e90ff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }